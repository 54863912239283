.timeline {
  @apply mx-20;
  list-style-type: none;
  display: flex;
  align-items: normal;
  justify-content: center;


  .node {
    @apply flex-1;
    transition: all 200ms ease-in;

    &:last-child {
      @apply flex-none;

      .timestamp {
        padding: 0;
      }

      .hook {
        padding: 0;
      }
    }

    .box {
      position: absolute;
      left: -50%;
      width: 100%;
      height: 130px;

      &.last {
        left: 50%;
      }

      .img {
        margin: auto;
        height: inherit;
      }
    }


    .timestamp {
      @apply relative;
      margin-bottom: 20px;
      padding: 0px 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 100;
      height: 130px;
    }

    .hook {
      padding: 0px 40px;
      display: flex;
      justify-content: center;
      border-top: 6px dotted #e6b0b1;
      position: relative;
      transition: all 200ms ease-in;

      &:before {
        content: '';
        width: 25px;
        height: 25px;
        background-color: white;
        border-radius: 25px;
        border: 6px solid #e6b0b1;
        position: absolute;
        top: -15px;
        left: -12.2px;
        transition: all 200ms ease-in;
      }

      .box {
        margin-top: 26px;
      }

    }
  }
}

@media (max-device-width: 768px) {

  .timeline {
    @apply ml-48 mr-0 mt-10 mb-24;

    flex-direction: column;
    height: auto;

    .node {
      .timestamp {
        height: 0;

        .box {
          left: -160px;
          width: auto;
          top: -50px;

          &.last {
            top: 100px;
          }
        }
      }

      .hook {
        border-top: none;
        border-left: 6px dotted #e6b0b1;
        flex-direction: column;

        &:before {
          content: '';
          left: -16px;
        }

        .box {
          position: relative;
          left: 0;
          top: -60px;
          width: auto;
          margin-top: 0;

          &.last {
            top: 69%;
            position: absolute;
            width: 100%;
          }
        }
      }
    }
  }


}